import { render, staticRenderFns } from "./Gmsj2.vue?vue&type=template&id=239f0bca&scoped=true&"
import script from "./Gmsj2.vue?vue&type=script&lang=js&"
export * from "./Gmsj2.vue?vue&type=script&lang=js&"
import style0 from "../style.css?vue&type=style&index=0&id=239f0bca&prod&scoped=true&lang=css&"
import style1 from "./Gmsj2.vue?vue&type=style&index=1&id=239f0bca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239f0bca",
  null
  
)

export default component.exports