<template>
    <div class="zt-page-content" style="min-width:1300px;">
        <!-- <div class="search-bar">
            <div class="flex flex-align-center">
                <div style="width:100px;font-size:18px;color:rgba(41,41,41,1);"><b>设计超市</b></div>
                <el-input v-model="query.key" placeholder="请输入内容" style="max-width: 500px;margin-left: 50px;">
                    <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
        </div> -->
        <div class="zt-block" style="min-width:1250px;">
            <div class="content-header flex">
                <div class="tab_menu flex-1 flex">
                    <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="menu_item flex" :class="{'cur':tabIndex == index}">
                        <div>{{item.name}}</div>
                        <img v-if="index==0" src="../../../assets/images/factory/border-right@2x.png">
                    </div>
                </div>
                <div class="flex">
                    <!-- <el-input v-model="query.key" placeholder="请输入内容" style="max-width: 400px;">
                        <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                    </el-input> -->
                    <!-- 请输入要搜索的词 -->
                    <div>
                        <el-input style="width:300px;" placeholder="请输入作品名称、设计公司名称、风格"
                            prefix-icon="el-icon-search" v-model="query.key"></el-input>
                    </div>
                    <div style="position: relative;left: -4px;margin-right:6px">
                        <el-button @click="search()" style="width:90px;" type="primary">搜索</el-button>
                    </div>
                </div>
            </div>
            <div class="content-con">
                <Gmsj1 v-if="tabIndex==0"></Gmsj1>
                <Gmsj2 v-if="tabIndex==1"></Gmsj2>
            </div>
        </div>
    </div>
</template>

<script>
    import { IMG_URL_PRE } from '@/config'
    import Gmsj1 from './Gmsj1.vue';
    import Gmsj2 from './Gmsj2.vue';
    export default {
        components: {
            Gmsj1,
            Gmsj2,
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                query:{
                    key:null,
                    type:"工厂款"
                },
                searchData:"",
                tabList:[
                    {name:"设计版权购买"},
                    {name:"分成合作"},
                ],
                tabIndex:"0",
            }
        },
        async created() {
            
        },
        mounted() {
            
        },
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                console.log(index);
            },
            search() {
                console.log("search");
            },
            
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    /*----选项卡----*/
    .content-header {
        width: 1250px;
        margin: 0 auto;
        padding: 17px 0 7px 0;
    }
    .tab_menu {
        
    }
    .tab_menu .menu_item>div{
        padding: 0 30px;
        height: 42px;
        line-height: 42px;
        margin:0 12px;
        cursor: pointer;
        font-size: 16px;
        color: #4D4D4D;
    }
    .tab_menu .menu_item>img {
        height: 50px;
    }
    .tab_menu .menu_item.cur>div{
        color: #fff;
        background: #5074EE;
        border-radius: 21px;
    }
    .content-con {
        width: 1250px;
        margin: 0 auto;
    }
</style>